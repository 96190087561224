import React, {
    ReactNode,
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
    useMemo
} from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import {
    BackofficeMembershipDetails,
    BackofficeSaveMitigationParams,
    BackofficeUserDeviceDetails,
    Cancellation,
    CancellationDetails,
    CancellationOptions,
    CancellationType,
    CurrentPlan,
    EnterpriseCancellationDetails,
    EnterpriseCancellationOptions,
    EnterpriseEnrollment,
    RmaEnums,
    RmaRequest
} from '@hero/hero-types';
import { mitigationSaveSelector } from '@hero/redux-data/backoffice/mitigationSave/selectors';
import { CancelMembershipParams } from '@hero/validators/forms/backoffice/cancelMembershipSchema';
import { SetRmaRequestPickUpParams } from '@hero/validators/forms/backoffice/rmaRequestSchema';

import useCancellation from '../../../hooks/useCancellation';
import useRmaRequest from '../../../hooks/useRmaRequest';
import useGetSubscriptionMitigation from '../../../hooks/useSubscriptionMitigationActions';
import { MitigationOffersSchemaParams } from '../validator/mitigationOffersSchema';
import { PlanOffersSchemaParams } from '../validator/planOffersSchema';
import useGetRTMTransferOptions, {
    HardshipOffer,
    PlanOffer
} from '../../../pages/Membership/api/useGetRTMTransferOptions';
import { HardshipOffersSchemaParams } from '../validator/hardshipOffers';
import { AxiosErrorResponse } from '../../../types';

// initial state is always `details` or `shipping`
// `details`, `review` and `shipping` correspond to the steps in the flow
// `success` is final state
// transitions are straightforward, all states have max 1 allowed action,
// but the next state sometimes depends on the outcome of background tasks
export type CancellationFlowState =
    | 'disabled-cancel'
    | 'details'
    | 'mitigation-offers'
    | 'plan-offers'
    | 'collect-payment-method'
    | 'rtm-to-d2c-success'
    | 'mitigation'
    | 'mitigation-success'
    | 'review'
    | 'shipping'
    | 'success';

type HandleTypeSelect = (selectedType: string | undefined) => void;

type HandleDetailsNext = (formValues: CancelMembershipParams) => void;

type HandleReviewConfirm = () => void;

type HandleCompleteRma = (formValues: SetRmaRequestPickUpParams) => void;

type RetainRTMtoD2cParams = {
    mitigation?: BackofficeSaveMitigationParams & { hardship?: string };
    plan?: string;
};

type CancellationStateMachineContextType = Readonly<{
    isOrganizationMember: boolean;
    isRmaFlow: boolean;
    showMitigation: boolean;
    step: number;
    steps: string[];
    heading: string;
    state: CancellationFlowState | undefined;
    cancellationType: CancellationType | undefined;
    handleTypeSelect: HandleTypeSelect;
    handleDetailsNext: HandleDetailsNext;
    handleReviewConfirm: HandleReviewConfirm;
    handleCompleteRma: HandleCompleteRma;
    continueCancellation: () => void;
    handleMitigationSave: (formValues: HardshipOffersSchemaParams) => void;
    handleChooseMitigationOffer: (formValues: MitigationOffersSchemaParams) => void;
    handleChoosePlanOffer: (formValues: PlanOffersSchemaParams) => void;
    onCreditCardSuccess: () => void;
    handleReportMitigation: (isSuccessful?: boolean) => void;
    currentPlan: CurrentPlan | undefined | null;
    retainRTMtoD2cParams: RetainRTMtoD2cParams;
    rtmPlanOffers?: PlanOffer[];
    rtmHardshipOffers?: HardshipOffer[];
    heroUserId?: string | number | null;
    membership?: BackofficeMembershipDetails;
    userDetails?: BackofficeUserDeviceDetails;
    rmaEnums?: RmaEnums;
    enrollment?: EnterpriseEnrollment;
    cancellationDetails?: CancellationDetails;
    orgCancellationDetails?: EnterpriseCancellationDetails;
    cancellationOptions?: CancellationOptions;
    orgCancellationOptions?: EnterpriseCancellationOptions;
    isCancelling: boolean;
    cancellingError: AxiosErrorResponse;
    cancellation?: Cancellation;
    deviceSerial?: string | null;
    rmaRequest?: RmaRequest | null;
    rmaPickupRequest?: RmaRequest | null;
    setRmaPickupError: AxiosErrorResponse;
    isSetRmaPickupLoading: boolean;
    createRmaDraftError: AxiosErrorResponse;
    isCreateRmaDraftLoading: boolean;
    eyebrow: string;
}>;

const CancellationStateMachineContext = createContext<
    CancellationStateMachineContextType | undefined
>(undefined);

export const useCancellationStateMachine = () => {
    const cancellationStateMachineContext = useContext(CancellationStateMachineContext);

    if (cancellationStateMachineContext === undefined) {
        throw new Error(
            `'useCancellationStateMachine' must be used within a 'CancellationStateMachineProvider'`
        );
    }

    return cancellationStateMachineContext;
};

type ProviderProps = {
    isOrganizationMember?: boolean;
    isCreateNewRma?: boolean;
    children: ReactNode;
    membership?: BackofficeMembershipDetails;
    userDetails?: BackofficeUserDeviceDetails;
    rmaEnums?: RmaEnums;
    enrollment?: EnterpriseEnrollment;
    cancellationDetails?: CancellationDetails;
    orgCancellationDetails?: EnterpriseCancellationDetails;
    cancellationOptions?: CancellationOptions;
    orgCancellationOptions?: EnterpriseCancellationOptions;
    rma?: RmaRequest;
    disableCreateRma?: boolean;
};

const disabledCancelPlans = [
    'legacy-1-year-prepaid',
    'legacy-2-year-prepaid',
    'legacy-1-year-free',
    'legacy-limited-free',
    'unlimited-free'
];

export const CancellationStateMachineProvider: React.FC<ProviderProps> = ({
    rma,
    isOrganizationMember = false,
    isCreateNewRma = false,
    disableCreateRma = false,
    membership,
    userDetails,
    rmaEnums,
    enrollment,
    cancellationDetails,
    orgCancellationDetails,
    cancellationOptions,
    orgCancellationOptions,
    children
}) => {
    const mitigationSave = useSelector(mitigationSaveSelector, shallowEqual);
    const [state, setState] = useState<CancellationFlowState | undefined>();
    const [heading, setHeading] = useState<string>('Cancellation');
    const [selectedType, setSelectedType] = useState<string | undefined>();
    const [detailsForm, setDetailsForm] = useState<CancelMembershipParams | undefined>();
    const [retainRTMtoD2cParams, setRetainRTMtoD2cParams] = useState<RetainRTMtoD2cParams>({
        mitigation: undefined,
        plan: undefined
    });

    const setReviewStep = () => setState('review');

    const {
        handleCancellationFormNext,
        handleCancellationConfirm,
        cancellationType,
        isCancellationDone,
        isCancelling,
        cancellingError,
        cancellationData,
        cancellationOrgData
    } = useCancellation({
        isOrganizationMember,
        membership,
        enrollment,
        orgCancellationOptions,
        cancellationOptions,
        setReviewStep
    });

    const { getSubscriptionMitigation, saveSubscriptionMitigation } =
        useGetSubscriptionMitigation();

    const cancellation = isOrganizationMember
        ? cancellationOrgData?.cancellation || orgCancellationDetails?.cancellation
        : cancellationData?.cancellation || cancellationDetails?.cancellation;

    const rmaRequest = isOrganizationMember
        ? orgCancellationDetails?.rma || rma
        : cancellationData?.rma || cancellationDetails?.rma || rma;

    const deviceSerial = isOrganizationMember
        ? enrollment?.member?.devices[0]?.device_serial
        : userDetails?.device?.external_serial ||
          cancellationDetails?.subscription.device_serial_number ||
          rmaRequest?.device_serial;

    const {
        handleCancellationShippingFormShow,
        handleSetRmaPickUp,
        isPickUpSet,
        createRmaDraftError,
        isCreateRmaDraftLoading,
        isSetRmaPickupLoading,
        setRmaPickupError,
        rmaDraft,
        rmaPickupRequest
    } = useRmaRequest({
        isOrganizationMember,
        cancellationDetails,
        orgCancellationDetails,
        cancellation,
        deviceSerial,
        rmaRequest,
        disableCreateRma
    });

    const isRetainRTMtoD2cFlow = selectedType === 'ec3';
    const heroUserId = enrollment?.member?.hero_user_id || membership?.hero_user_id || undefined;

    const { data: rtmTransferOptions } = useGetRTMTransferOptions({
        hero_user_id: heroUserId ? +heroUserId : undefined,
        enabled: isRetainRTMtoD2cFlow && state === 'details'
    });

    const showMitigation = useMemo<boolean>(() => {
        // supported mitigation plans: https://herohealth.atlassian.net/browse/HWM-13440

        const usedMitigationPlans = [
            'basic-extended-trial',
            'commitment-monthly-12months',
            'commitment-monthly-24months',
            'basic',
            'basic-extended-trial-old'
        ].includes(membership?.current_plan?.storefront_key || '');

        const isInPriceIncreaseFlow =
            typeof membership?.scheduled_membership_plan_change_at === 'string' &&
            membership?.scheduled_membership_plan_change_at.length;

        return !isOrganizationMember && usedMitigationPlans && !isInPriceIncreaseFlow;
    }, [isOrganizationMember, membership]);

    const step: number = useMemo(() => {
        if (showMitigation) {
            if (state === 'details') return 1;
            if (state === 'mitigation') return 2;
            if (state === 'review') return 3;
            if (state === 'shipping') return 4;
            if (state === 'success') return 5;
            return 0; // basically N/A
        } else {
            if (state === 'details') return 1;
            if (['plan-offers', 'mitigation-offers'].includes(state || '')) return 2;
            if (state === 'review') return 2;
            if (state === 'shipping') return 3;
            if (state === 'collect-payment-method') return 3;
            if (state === 'success' || state === 'rtm-to-d2c-success') return 4;
            return 0; // basically N/A
        }
    }, [state, showMitigation]);

    const isRmaFlow: boolean = useMemo(() => {
        return !!selectedType && !['c1', 'ec1', 'ec3'].includes(selectedType);
    }, [selectedType]);

    const steps: string[] = useMemo(() => {
        const mitigationSteps = ['Details', 'Retain', 'Review', 'Shipping'];

        if (isRetainRTMtoD2cFlow && step !== 4) {
            return mitigationSteps;
        }
        const checkMitigationSteps = showMitigation
            ? mitigationSteps
            : ['Details', 'Review', 'Shipping'];

        if (step === 4) {
            return [];
        }

        if (isCreateNewRma) {
            return ['Details', 'Review', 'Shipping'];
        }
        const rmaFlowSteps = isRmaFlow ? checkMitigationSteps : ['Details', 'Review'];

        return !step ? [] : rmaFlowSteps;
    }, [step, isRmaFlow, isCreateNewRma, showMitigation, isRetainRTMtoD2cFlow]);

    useEffect(() => {
        if (isCreateNewRma) {
            setState('shipping');
        }
    }, [isCreateNewRma]);

    // initialize state
    useEffect(() => {
        // skip if already set
        if (state) return;

        // skip if enrollment details not yet fetched
        if (isOrganizationMember && !enrollment) return;

        // skip if membership details not yet fetched
        if (!isOrganizationMember && !membership) return;

        const isCancelled = isOrganizationMember
            ? enrollment?.member?.status === 'CANCELLED'
            : membership?.status === 'cancelled';
        // instead of (double) fetching the cancellation details here,
        // just to verify that RMA is not complete yet, we rely on parent
        // components to do the check and render this only when it makes sense
        if (isCancelled) {
            setState('shipping');
        } else if (
            disabledCancelPlans.includes(
                membership?.current_plan?.storefront_key || membership?.current_plan?.key || ''
            )
        ) {
            setState('disabled-cancel');
        } else {
            setState('details');
        }
    }, [isOrganizationMember, enrollment, state, membership]);

    useEffect(() => {
        state === 'details' && showMitigation && getSubscriptionMitigation(membership?.id || 0);
    }, [showMitigation, membership, state, getSubscriptionMitigation]);

    // useEffect(() => {
    //     if (
    //         ['mitigation', 'details', 'mitigation-offers', 'plan-offers'].includes(state || '') &&
    //         isPreviewReady
    //     ) {
    //         setState('review');
    //     }
    // }, [state, isPreviewReady]);

    useEffect(() => {
        if (state === 'mitigation' && mitigationSave?.length) {
            setState('mitigation-success');
        }
    }, [state, mitigationSave]);

    const handleReportMitigation = useCallback(
        (isSuccessful = false) => {
            !isOrganizationMember &&
                saveSubscriptionMitigation({
                    mitigation_type: 1,
                    subscription_id: membership?.id || 0,
                    successful_mitigation: isSuccessful
                });
        },
        [saveSubscriptionMitigation, membership, isOrganizationMember]
    );

    useEffect(() => {
        if (state === 'review' && isCancellationDone) {
            if (isRmaFlow) {
                handleReportMitigation();
                setState('shipping');
            } else {
                setState('success');
                setHeading('Membership Cancelled');
            }
        }
    }, [state, isRmaFlow, isCancellationDone, handleReportMitigation]);

    useEffect(() => {
        if (state === 'shipping') {
            handleCancellationShippingFormShow();
        }
    }, [state, handleCancellationShippingFormShow]);

    useEffect(() => {
        if (state === 'shipping' && isPickUpSet) {
            setState('success');
            setHeading('RMA Created');
        }
    }, [state, isPickUpSet]);

    useEffect(() => {
        state === 'mitigation-success' && setHeading('Great work');
    }, [state]);

    const handleTypeSelect: HandleTypeSelect = useCallback(
        (value: string | undefined) => {
            if (state !== 'details') return;

            setSelectedType(value);
        },
        [state]
    );

    const handleDetailsNext: HandleDetailsNext = useCallback(
        (formValues) => {
            if (state !== 'details') return;

            setDetailsForm(formValues);

            if (isRetainRTMtoD2cFlow) {
                setState('mitigation-offers');
                return;
            }

            if (showMitigation) {
                const isResolved = formValues.isCancellationProblemResolved;

                setState(isResolved ? 'mitigation-success' : 'mitigation');

                isResolved && handleReportMitigation(true);
            } else {
                handleCancellationFormNext(formValues);
            }
        },
        [
            state,
            handleCancellationFormNext,
            showMitigation,
            handleReportMitigation,
            isRetainRTMtoD2cFlow
        ]
    );

    const continueCancellation = useCallback(() => {
        detailsForm && handleCancellationFormNext(detailsForm);
    }, [detailsForm, handleCancellationFormNext]);

    const handleReviewConfirm: HandleReviewConfirm = useCallback(() => {
        if (state !== 'review') return;

        handleCancellationConfirm();
    }, [state, handleCancellationConfirm]);

    const handleCompleteRma: HandleCompleteRma = useCallback(
        (formValues) => {
            if (state !== 'shipping') return;

            handleSetRmaPickUp(formValues);
        },
        [state, handleSetRmaPickUp]
    );

    const handleMitigationSave = useCallback(
        (formValues: HardshipOffersSchemaParams) => {
            const attributes = {
                subscription_id: membership?.id || enrollment?.member?.id || 0,
                successful_mitigation: true
            };

            let data: BackofficeSaveMitigationParams &
                Pick<HardshipOffersSchemaParams, 'hardship'> = {
                ...attributes,
                mitigation_type: formValues.mitigation_type,
                discount_type: formValues.discount_type,
                months: formValues.hardship === '1-month-free' ? 1 : formValues.months,
                hardship: formValues.hardship
            };

            if (isRetainRTMtoD2cFlow) {
                setRetainRTMtoD2cParams({ plan: undefined, mitigation: data });
                setState('collect-payment-method');
            } else {
                saveSubscriptionMitigation(data);
            }
        },
        [saveSubscriptionMitigation, membership, isRetainRTMtoD2cFlow, enrollment]
    );

    const handleChooseMitigationOffer = useCallback((formValues: MitigationOffersSchemaParams) => {
        setState(formValues.mitigation_offers === 'regular-plans' ? 'plan-offers' : 'mitigation');
    }, []);

    const handleChoosePlanOffer = useCallback((formValues: PlanOffersSchemaParams) => {
        setRetainRTMtoD2cParams({ plan: `${formValues.plan_offers}`, mitigation: undefined });
        setState('collect-payment-method');
    }, []);

    const onCreditCardSuccess = () => {
        setHeading('Great Work');
        setState('rtm-to-d2c-success');
    };

    const eyebrow: string = useMemo(() => {
        if (isOrganizationMember) {
            const organizationName = enrollment?.organization.name || '-';
            const userFullName =
                `${enrollment?.member?.contact?.first_name || ''} ${
                    enrollment?.member?.contact?.last_name || ''
                }`.trim() ||
                `${enrollment?.contact?.first_name || ''} ${
                    enrollment?.contact?.last_name || ''
                }`.trim() ||
                '-';
            const userEmail =
                enrollment?.member?.contact?.email || enrollment?.contact?.email || '-';
            const deviceSerial = enrollment?.member?.devices[0]?.device_serial || '-';
            return `${organizationName} / ${userFullName} / ${userEmail} / ${deviceSerial}`;
        } else {
            const userFullName =
                `${userDetails?.user?.first_name || ''} ${userDetails?.user?.last_name || ''}`.trim() ||
                '-';
            const userEmail = membership?.order_email || '-';
            const deviceSerial =
                userDetails?.device?.external_serial ||
                cancellationDetails?.subscription.device_serial_number ||
                membership?.serial ||
                '-';
            return `${userFullName} / ${userEmail} / ${deviceSerial}`;
        }
    }, [isOrganizationMember, enrollment, membership, userDetails, cancellationDetails]);

    return (
        <CancellationStateMachineContext.Provider
            value={{
                isOrganizationMember,
                isRmaFlow,
                step,
                steps,
                heading,
                state,
                cancellationType,
                handleTypeSelect,
                handleDetailsNext,
                handleReviewConfirm,
                handleCompleteRma,
                continueCancellation,
                handleChooseMitigationOffer,
                handleChoosePlanOffer,
                onCreditCardSuccess,
                showMitigation,
                handleMitigationSave,
                handleReportMitigation,
                currentPlan: membership?.current_plan,
                retainRTMtoD2cParams,
                rtmPlanOffers: rtmTransferOptions?.data.data.plans,
                rtmHardshipOffers: rtmTransferOptions?.data.data.offers,
                heroUserId: membership?.hero_user_id || enrollment?.member?.hero_user_id,
                membership,
                userDetails,
                rmaEnums,
                enrollment,
                cancellationDetails,
                orgCancellationDetails,
                cancellationOptions,
                orgCancellationOptions,
                isCancelling,
                cancellingError,
                cancellation,
                deviceSerial,
                rmaRequest: rmaDraft || rmaRequest,
                rmaPickupRequest,
                createRmaDraftError,
                isCreateRmaDraftLoading,
                isSetRmaPickupLoading,
                setRmaPickupError,
                eyebrow
            }}
        >
            {children}
        </CancellationStateMachineContext.Provider>
    );
};
